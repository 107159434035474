import React, {Component} from "react"
import PositionListScrubber from "./PositionListScrubber";
import {CircularProgress, FormControl, InputLabel, MenuItem, Select, TablePagination, TextField,} from "@mui/material";
import jsonJobs from "../../jobs.json"

let lang = process.env.LANG;
if (lang) {
    lang = lang.toUpperCase();
}

let isFrench = lang === "FR";

const removeAccents = (str) => {
    const accents = {
        a: 'àáâãäåæ', c: 'ç', e: 'èéêëæ', i: 'ìíîï', n: 'ñ', o: 'òóôõöø', s: 'ß', u: 'ùúûü', y: 'ÿ'
    }
    let newStr = "";
    for (let char in str) {
        let continueFlag = false;
        for (let accent in accents) {
            if (accents[accent].includes(str[char])) {
                newStr += accent;
                continueFlag = true;
                break;
            }
        }
        if (continueFlag) continue;
        newStr += str[char];
    }
    return newStr;
};

class Search extends Component {
    state = {
        tableDisplay: "none",
        jobList: [],
        filterJobList: [],
        searchFieldsDisabled: true,
        searchTerms: "",
        type: "",

        locationOptions: [],
        categoryOptions: [],
        jobStatusOptions: [],
        shiftTypeOptions: [],

        locationTerm: "",
        categoryTerm: "",
        jobStatusTerm: "",
            shiftTypeTerm: "",


        pagination: [],
    }

    /**
     * React lifecycle method to fetch the data
     */

    async componentDidMount() {
        this.checkSearchParameters();
        const jobData = PositionListScrubber(jsonJobs, lang);
        this.setState({jobList: jobData})
        let url = new URL(window.location.href);
        let term = url.searchParams.get("term");
        if (term) {
            if (term) {
                this.setState({filterJobList: this.searchQuery(term, "", jobData)})
            }
        } else {
            this.setState({filterJobList: jobData})
        }
        this.buildIndex(jobData)
    }

    removeAccents = (str) => {
        const accents = {
            a: 'àáâãäåæ', c: 'ç', e: 'èéêëæ', i: 'ìíîï', n: 'ñ', o: 'òóôõöø', s: 'ß', u: 'ùúûü', y: 'ÿ'
        }
        let newStr = "";
        for (let char in str) {
            let continueFlag = false;
            for (let accent in accents) {
                if (accents[accent].includes(str[char])) {
                    newStr += accent;
                    continueFlag = true;
                    break;
                }
            }
            if (continueFlag) continue;
            newStr += str[char];
        }
        return newStr;
    };
    replaceAll = (haystack, needle, replacement) => {
        while (haystack.indexOf(needle) >= 0) {
            haystack = haystack.replace(needle, replacement);
        }
        return haystack
    }

    searchQuery = (searchTerm, searchType, jobList) => {
        let tempList = [];
        searchTerm = searchTerm.toLowerCase();
        searchTerm = this.removeAccents(searchTerm)

        const searchTargets = ["status", "jobCategory", "shiftType", "address", "title"];
        for (let job in jobList) {
            if (jobList.hasOwnProperty(job)) {
                let addToList = false;
                if (searchTerm === "") {
                    tempList.push(jobList[job]);
                    break;
                }
                for (let target in searchTargets) {
                    if (jobList[job].hasOwnProperty(searchTargets[target]) && jobList[job][searchTargets[target]] !== undefined) {
                        if (this.removeAccents(jobList[job][searchTargets[target]].toLowerCase()).includes(searchTerm) || searchTerm === "") {
                            tempList.push(jobList[job]);
                            break;
                        }
                    }

                }
            }
        }
        return tempList;
    }

    populateSearchFields = (jobList) => {
        let locations = [];
        let categories = []
        let statuses = [];
        let shiftsTypes = []
        for (let job in jobList) {
            if (jobList.hasOwnProperty(job)) {

                if (jobList[job].hasOwnProperty('city') && jobList[job].hasOwnProperty('state')) {
                    let cityState = `${jobList[job].city.trim()}${!jobList[job].isCounty ? " - " : ""}${!jobList[job].isCounty ? jobList[job].state : ""}`;
                    if (!locations.includes(cityState)) {
                        locations.push(cityState);
                    }
                }
                if (jobList[job].hasOwnProperty('jobCategory')) {
                    if (!categories.includes(jobList[job].jobCategory)) {
                        categories.push(jobList[job].jobCategory);
                    }
                }
                if (jobList[job].hasOwnProperty('status')) {
                    if (!statuses.includes(jobList[job].status)) {
                        statuses.push(jobList[job].status);
                    }
                }
                if (jobList[job].hasOwnProperty('shiftType')) {
                    if (!shiftsTypes.includes(jobList[job].shiftType)) {
                        if(jobList[job].shiftType !== 'All'){
                            shiftsTypes.push(jobList[job].shiftType);
                        }
                    }
                }
            }
        }
        locations.sort();
        categories.sort();
        shiftsTypes.sort();
        statuses.sort();

        this.setState({
            locationOptions: locations,
            jobStatusOptions: statuses,
            categoryOptions: categories,
            shiftTypeOptions: shiftsTypes,

            searchFieldsDisabled: false,
            tableDisplay: "block"
        });

        // Hide the progress circle.
        let progressCircle = document.getElementById("job_progress_circle");
        progressCircle.style.display = "none";

    }
    checkSearchParameters = () => {
        let url = new URL(window.location.href);
        let term = url.searchParams.get("term");
        if (term) {
            if (term) {
                this.setState({searchTerms: term});
            }
        }
    }
    adjustSearchParameters = () => {
        let url = new URL(window.location.href);
        let term = url.searchParams.get("term");
        if (term) {
            if (term) {
                this.setState({searchTerms: term});
            }
        }
    }
    buildIndex = (jobList) => {
        this.populateSearchFields(jobList);
        this.adjustSearchParameters();
    }
    setPage = (pageNumber) => {
        this.setState({page: pageNumber});
    }


    render() {

        const {
            filterJobList,
            page = 0,
            searchFieldsDisabled,

            locationTerm,
            locationOptions,
            categoryTerm,
            categoryOptions,
            jobStatusTerm,
            jobStatusOptions,
                shiftTypeTerm,
            shiftTypeOptions,

            rowsPerPage = 25,
            searchTerms,

            jobList,
        } = this.state;

        const handleSearchChange = (event) => {
            this.setState({searchTerms: event.target.value});
            rebuildTableIndex(event.target.value, locationTerm, categoryTerm, jobStatusTerm);

        }

        const rebuildTableIndex = (searchTerm, locationTerm, categoryTerm, jobStatusTerm, shiftTypeTerm) => {
            // this.setState({page: 0});
            // this.setState({filterJobList: this.searchQuery(searchTerm, "", jobList)});
            let tempJobList = [];
            searchTerm = searchTerm.toLowerCase();
            searchTerm = removeAccents(searchTerm).trim();

            const isZipCode = searchTerm.length === 5 && !isNaN(searchTerm);
            if (searchTerm.length === 5 && !isNaN(searchTerm)) {

            }


            for (let job in jobList) {

                if (jobList.hasOwnProperty(job)) {

                    let includeViaZipCode = false;
                    if (isZipCode) {
                        includeViaZipCode = jobList[job].nearbyZipCodes.includes(searchTerm);
                    }
                    // console.table(jobList[job])
                    if (removeAccents(jobList[job].title.toLowerCase()).includes(searchTerm)
                        || removeAccents(jobList[job].address.toLowerCase()).includes(searchTerm)
                        || removeAccents(jobList[job].shiftType.toLowerCase()).includes(searchTerm)
                        || removeAccents(jobList[job].jobCategory.toLowerCase()).includes(searchTerm)
                        || removeAccents(jobList[job].status.toLowerCase()).includes(searchTerm)
                        || includeViaZipCode || searchTerm === '') {
                        let addToList = true;

                        // If there is a city specified
                        if(locationTerm !== "" && locationTerm !== "Any Location") {
                            let cityState = jobList[job].city.includes(" - ") ? jobList[job].city :jobList[job].city + " - " + jobList[job].state;
                            addToList = cityState === locationTerm;
                            if (!addToList) continue;
                        }
                        // If there is a province specified
                        if(categoryTerm !== "" && categoryTerm !== "Any Career Category"){

                            addToList = jobList[job].jobCategory === categoryTerm;
                            if (!addToList) continue;
                        }
                        // If there is a banner specified
                        if (jobStatusTerm !== "" && jobStatusTerm !== "Any Career Status") {

                            addToList = jobList[job].status === jobStatusTerm;
                            if (!addToList) continue;
                        }
                        // If there is a banner specified
                        if (shiftTypeTerm && shiftTypeTerm !== "" && shiftTypeTerm !== "Any Shift Type") {

                            addToList = jobList[job].shiftType === shiftTypeTerm;
                            if (!addToList) continue;
                        }
                        if (addToList) tempJobList.push(jobList[job]);
                    }
                }
            }
            this.setState({filterJobList: tempJobList});

        }

        const handleChangePage = (event, newPage) => {
            this.setState({page: newPage});
        };

        const handleLocationChange = (event) => {
            this.setState({location: event.target.value});
            this.setState({locationTerm: event.target.value});
            rebuildTableIndex(searchTerms, event.target.value, categoryTerm, jobStatusTerm, shiftTypeTerm);
        };
        const handleJobCategoryChange = (event) => {
            this.setState({jobCategory: event.target.value});
            this.setState({jobCategoryTerm: event.target.value});
            rebuildTableIndex(searchTerms, locationTerm, event.target.value, jobStatusTerm, shiftTypeTerm);
        };
        const handleJobStatusChange = (event) => {
            this.setState({jobStatus: event.target.value});
            this.setState({jobStatusTerm: event.target.value});
            rebuildTableIndex(searchTerms, locationTerm, categoryTerm, event.target.value, shiftTypeTerm);
        };
        const handleShiftTypeChange = (event) => {
            this.setState({shiftType: event.target.value});
            this.setState({shiftTypeTerm: event.target.value});
            rebuildTableIndex(searchTerms, locationTerm, categoryTerm, jobStatusTerm, event.target.value);
        };
        const handleChangeRowsPerPage = (event) => {
            this.setState({rowsPerPage: parseInt(event.target.value, 10)});
            this.setState({page: 0});
        };

        return (
          <div>
            <section className={"results dataTables_wrapper mb5 no-footer"}>
              <div className={"search-input-fields mt2-md pb3-sm r fww"}>
                {/*Search*/}
                <TextField
                  disabled={searchFieldsDisabled}
                  className={"mr2 mr0-md mt3 w100-ml"}
                  id="search_field_search"
                  style={{
                    marginTop: "1rem",
                    marginRight: "0.5rem",
                    width: 310,
                  }}
                  label={"Search: Title, Location, Shift, etc.."}
                  onChange={handleSearchChange}
                  value={searchTerms}
                  sx={{
                    ["@media (max-width:900px)"]: {
                      marginRight: "0rem !important",
                    },
                  }}
                  variant="outlined"
                />

                {/* Location */}
                <FormControl
                  className={"mr2 mt3 mr0 w100-ml"}
                  sx={{
                    ["@media (max-width:900px)"]: {
                      marginRight: "0 !important",
                    },
                    marginTop: "1rem",
                    marginRight: "0.5rem",
                    width: 160,
                  }}
                >
                  <InputLabel>Location</InputLabel>
                  <Select
                    disabled={searchFieldsDisabled}
                    labelId="search-field-location-label"
                    id="search_field_location"
                    value={this.location}
                    label={"Location"}
                    onChange={handleLocationChange}
                    style={{ height: "53px" }}
                  >
                    <MenuItem value={"Any Location"}>Any Location</MenuItem>
                    {locationOptions.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/*Job Category*/}
                <FormControl
                  className={"mr2 mt3 w50-ml"}
                  style={{
                    ["@media (max-width:900px)"]: {
                      paddingRight: "0.5rem !important",
                    },
                    marginTop: "1rem",
                    marginRight: "0rem",
                    width: 160,
                  }}
                >
                  <InputLabel>Career Category</InputLabel>
                  <Select
                    disabled={searchFieldsDisabled}
                    labelId="search-field-category-label"
                    id="search_field_category"
                    value={this.category}
                    label={"Category"}
                    onChange={handleJobCategoryChange}
                    style={{ height: "53px" }}
                  >
                    <MenuItem value={"Any Career Category"}>
                      Any Career Category
                    </MenuItem>
                    {categoryOptions.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/*Job Status*/}
                <FormControl
                  className={"mr2 mt3 w50-ml"}
                  sx={{
                    ["@media (min-width:900px)"]: {
                      marginRight: "0.5rem !important",
                    },
                  }}
                  style={{
                    marginTop: "1rem",
                    paddingLeft: "0.5rem",
                    marginRight: "0rem",
                    width: 160,
                  }}
                >
                  <InputLabel style={{ paddingLeft: "8px" }}>
                    Career Status
                  </InputLabel>
                  <Select
                    disabled={searchFieldsDisabled}
                    labelId="search-field-job-status-label"
                    id="search_field_job_status"
                    value={this.jobStatus}
                    label={"Career Status"}
                    onChange={handleJobStatusChange}
                    style={{ height: "53px" }}
                  >
                    <MenuItem value={"Any Career Status"}>
                      Any Career Status
                    </MenuItem>
                    {jobStatusOptions.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  className={"mr2 mt3 mr0 w100-ml"}
                  sx={{
                    ["@media (max-width:900px)"]: {
                      marginRight: "0 !important",
                    },
                    marginTop: "1rem",
                    marginRight: "0.5rem",
                    width: 160,
                  }}
                >
                  <InputLabel style={{ paddingLeft: "8px" }}>
                    Shift Type
                  </InputLabel>
                  <Select
                    disabled={searchFieldsDisabled}
                    labelId="search-field-job-shift-type"
                    id="search_field_shit_type"
                    value={this.shiftType}
                    label={"Shift Type"}
                    onChange={handleShiftTypeChange}
                    style={{ height: "53px" }}
                  >
                    <MenuItem value={"Any Shift Type"}>Any Shift Type</MenuItem>
                    {shiftTypeOptions.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={"jobs-list "}>
                <div
                  className={
                    "job-list-row d-n-lg mt4 r-c-lg ac jsb w100 p4 px4"
                  }
                  style={{ background: "#23A0D8", color: "white" }}
                >
                  <div className={"c w30 w100-lg"}>Title</div>
                  <div className={"c w20 w100-lg"}>Location</div>
                  <div className={"c w15 w100-lg"}>Career Category</div>
                  <div className={"c w15 w100-lg"}>Status</div>
                  <div className={"c w15 w100-lg"}>Shift Type</div>
                  <div className={"c w20 jc afe w100-lg"}></div>
                </div>
                <div id={"job_progress_circle"} className={"r w100 jc ac my6 "}>
                  <CircularProgress style={{ width: 60, height: 60 }} />
                </div>
                {filterJobList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((job) => (
                    <div
                      className={
                        "job-list-row mt4 mb4-sm mt1-sm r-c-lg ac jsb w100 p3 px4"
                      }
                      style={{
                        borderRadius: "4px",
                        border: "solid 1px rgba(0,0,0,0.25)",
                      }}
                    >
                      <div className={"c w30 ac-lg w100-lg"}>
                        <a
                          style={{
                            fontSize: 16,
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                          className={"tac-md"}
                          href={job.descUrl}
                        >
                          {job.title}
                        </a>
                      </div>
                      <div className={"c w20 ac-lg tac-lg w100-lg pr2"}>
                        {job.city} {!job.isCounty ? "-" : ""}{" "}
                        {!job.isCounty ? job.state : ""}
                      </div>
                      <div className={"c w15 ac-lg w100-lg pr2"}>
                        {job.jobCategory}
                      </div>
                      <div className={"c w15 ac-lg w100-lg pr2"}>
                        {job.status}
                      </div>
                      <div className={"c w15 ac-lg w100-lg pr2"}>
                        {!job.shiftType ? "All" : job.shiftType}
                      </div>
                      {/* <div className={"c w15 ac-lg tac-lg w100-lg"}>{adjustSearchParams(job.jobCategory, lang)}<br/>{jobTypeText[job.type]}
                            </div> */}
                      <div className={"c w20 jc ac-lg afe w100-lg"}>
                        <button className={"button"} style={{ width: 160 }}>
                          <a style={{ color: "#ffffff" }} href={job.descUrl}>
                            Apply Now
                          </a>
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={filterJobList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Rows per page"}
              />
            </section>
          </div>
        );
    }
}


export default Search;
